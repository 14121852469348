import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Docker from '../assets/images/customers/docker.svg'
import Factorial from '../assets/images/customers/factorial.svg'
import Matillion from '../assets/images/customers/matillion.svg'
import Miro from '../assets/images/customers/miro.svg'
import Pleo from '../assets/images/customers/pleo.svg'
import Truelayer from '../assets/images/customers/truelayer.svg'
import Webflow from '../assets/images/customers/webflow.svg'
import WeTransfer from '../assets/images/customers/wetransfer.svg'

import g2badge from '../assets/images/G2Badge.svg'
import theme from '../theme'
import Block from './Block'

type Props = {
  hideTitle?: boolean
}

const G2AndCustomerLogos = ({ hideTitle }: Props) => {
  return (
    <CustomersContainer>
      {!hideTitle ? (
        <Block type="padding-small" size="large" centered>
          <h5>Trusted by effective engineering&nbsp;organizations</h5>
        </Block>
      ) : null}
      <InlineBlock>
        <G2Badge />
        <Divider />
        <ul className="logos-container">
          <li>
            <img src={Miro} alt="Miro" />
            <Link to="/story/miro/">Read story</Link>
          </li>
          <li>
            <img src={Docker} alt="Docker" />
          </li>
          <li>
            <img src={Webflow} alt="Webflow" />
          </li>
          <li>
            <img src={Matillion} alt="Matillion" />
          </li>
          <li>
            <img src={WeTransfer} alt="WeTransfer" />
          </li>
          <li>
            <img src={Truelayer} alt="Truelayer" />
            <Link to="/story/truelayer/">Read story</Link>
          </li>
          <li>
            <img src={Pleo} alt="Pleo" />
            <Link to="/story/pleo/">Read story</Link>
          </li>
          <li>
            <img src={Factorial} alt="Factorial" />
            <Link to="/story/factorial/">Read story</Link>
          </li>
        </ul>
      </InlineBlock>
    </CustomersContainer>
  )
}

export default G2AndCustomerLogos

const Divider = styled.div`
  content: '';
  height: 180px;
  width: 4px;
  background-color: ${theme.colors.dataBlue};
  opacity: 0.1;
  margin-right: 113px;
  border-radius: 99999px;

  @media screen and (max-width: ${theme.breakpoints['medium']}) {
    margin-left: 0;
    margin-right: 0;
    margin-top: ${theme.space[6]};
    margin-bottom: ${theme.space[6]};
    height: 4px;
    width: 80vw;
  }
`

function G2Badge() {
  return (
    <OpticallyAlign>
      <img width="128" src={g2badge} alt="G2 Leader Fall 2023" />
    </OpticallyAlign>
  )
}
const OpticallyAlign = styled.div`
  height: 166px;
  padding-left: 40px;
  margin-left: 32px;
  margin-right: 113px;

  @media screen and (max-width: ${theme.breakpoints['medium']}) {
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
  }
`

const InlineBlock = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1300px;

  @media screen and (max-width: ${theme.breakpoints['medium']}) {
    flex-direction: column-reverse;
  }
`

const CustomersContainer = styled.div`
  padding: 32px 0 32px 0;

  p {
    font-weight: 700;
  }

  .logos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 12px;
    column-gap: 19px;
    padding: 0;
    max-width: 860px;

    & > li {
      width: calc(50% - 16px);
      height: 64px;
      max-width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 16px;
      margin: 0;

      a {
        position: absolute;
        top: 0;
        bottom: 0;
        letter-spacing: 1px;
        font-size: 0.7rem;
        text-transform: uppercase;
        color: #1a66d8;
        opacity: 0;
        font-weight: 700;
        padding-top: 48px;

        &:hover {
          opacity: 1;
        }
      }

      img {
        max-width: 100%; /* max dimensions on the images so they always fit */
        max-height: 40px;
        height: auto; /* maintain aspect ratio */
        width: auto;
      }
    }
  }

  @media screen and (max-width: ${theme.breakpoints['medium']}) {
    .logos-container {
      justify-content: center;
    }
  }
`
